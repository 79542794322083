const tags = document.querySelectorAll('.nav-item.dropdown');
const noDropdownLinks = document.querySelectorAll('.nav-link.blue-arrow');
const mobileToggler = document.querySelector('.navbar-toggler');


function closeOpenItems(e) {
  let openMenus = document.querySelectorAll('.nav-link.dropdown-toggle.show');
  openMenus.forEach(function (menus) {
    if (menus.id !== e.target.id) {
      menus.classList.remove('show');
      menus.nextElementSibling.classList.remove('show');
    }

    if (noDropdownLinks) {
      noDropdownLinks.forEach(function (item) {
        item.classList.remove('blue-arrow');
      });
    }

    if (menus.id === e.target.id) {
      e.preventDefault();
      e.stopPropagation();
    }
  });
}


function clickHandler() {

  tags.forEach(tag => {
    tag.addEventListener('click', function (e) {


      if (e.target.tagName == 'A') {
        if (window.getComputedStyle(mobileToggler).visibility !== 'hidden') {
          mobileToggler.click();
        }

        if (e.target.classList.contains('dropdown-toggle')) {
          closeOpenItems(e);
        }
        
      }
      else {
        e.preventDefault();
        e.stopPropagation();
      }

    });

    tag.addEventListener('hide.bs.dropdown', function (e) {
      closeOpenItems(e);
      e.stopPropagation();
    })

  });
}


document.addEventListener('DOMContentLoaded', function()  {

  clickHandler();
});
