document.addEventListener('DOMContentLoaded', () => {
  function adjustMain() {
    requestAnimationFrame(() => {
      let height = 0;
      if (window.innerWidth >= 992 && document.querySelectorAll('#header .dropdown-menu.show').length) {
        height = Array.from(document.querySelectorAll('#header .dropdown-menu.show')).map((dd) => dd.offsetHeight).reduce((prev,curr) => Math.max(prev, curr));
      }
      document.querySelector('#main').style.marginTop = height + 'px';
    });
  }
  window.addEventListener('resize', () => {
    adjustMain();
  });
  document.querySelector('#header').addEventListener('shown.bs.dropdown', adjustMain);
  document.querySelector('#header').addEventListener('hidden.bs.dropdown', adjustMain);

  adjustMain();
})