function clickHandler(selector, fn) {
  [].forEach.call(document.querySelectorAll(selector), function (el) {
    el.addEventListener('click', fn);
  });
}

function changeHandler(selector, fn) {
  [].forEach.call(document.querySelectorAll(selector), function (el) {
    el.addEventListener('change', fn);
  });
}

function hasClass(obj, className) {
  if (obj.classList.contains(className)) {
    return true;
  } else {
    return false;
  }
}

function addClass(obj, className) {
  obj.classList.add(className);
}

function removeClass(obj, className) {
  obj.classList.remove(className);
}

document.addEventListener('DOMContentLoaded', function () {

  clickHandler('.salesOfficers .salesOfficersFilter .showAll', function (e) {
    e.preventDefault();
    var salesOfficers = this.closest('.salesOfficers');
    [].forEach.call(salesOfficers.querySelectorAll('.officer'), function (el) {
      removeClass(el, 'activeSlug');
    });
    if (hasClass(salesOfficers, 'showAll')) {
      removeClass(salesOfficers, 'showAll');
    } else {
      addClass(salesOfficers, 'showAll');
      [].forEach.call(salesOfficers.querySelectorAll('.stateFilter'), function (el) {
        el.value = '';
      });
    }
  });

  changeHandler('.salesOfficers .salesOfficersFilter .stateFilter', function (e) {
    var targetSlug = this.value;
    var salesOfficers = this.closest('.salesOfficers');
    if (targetSlug != '') {
      removeClass(salesOfficers, 'showAll');
      [].forEach.call(salesOfficers.querySelectorAll('.officer'), function (el) {
        if (hasClass(el, targetSlug)) {
          addClass(el, 'activeSlug');
        } else {
          removeClass(el, 'activeSlug');
        }
      });
    }
  });
});
